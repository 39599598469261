html{
  background-color: #f7f7f7;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-navbar {
  width: 100%;
}

.error-page-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vmin;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-welcome-text{
  color: #FFF;
  text-align: center;
  margin-top: 20px;
}

.inventory-table {
  background-color: #FFF !important;
}

.inventory-table tr, .inventory-table td  {
  height: 10px;
  padding-top: .2rem !important;
  padding-bottom: .2rem !important;
  margin: 0;
}

.react-datepicker__input-container input {
  border-radius: 3px;
    box-shadow: none;
    border: 2px solid #dee2e6;
    text-align: center;
}

.react-confirm-alert-button-group {
  justify-content: center!important;
}

.filepond--root {
    max-width: 240px;
}
