.header-table {
  margin-bottom: 24px;
}
.header-label {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  color: var(--grey) !important;
}

th {
  border: none !important;
  white-space: nowrap;
}

tbody > tr {
  box-shadow: 0 2px 4px 0 rgba(184, 184, 184, 0.5);
  border-radius: 9px;
}

td {
  font-size: 14px;
  word-break: break-all;
  border: none !important;
  vertical-align: middle !important;
  background-color: #ffffff;
  height: 80px;
  overflow: hidden;
  color: var(--grey);
}

.table {
  border-collapse: separate;
  border-spacing: 0 24px;
}

td:first-child {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

td:last-child {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  max-width: 40px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
