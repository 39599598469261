@import '../../utilities/colors.css';

.user-cards-container {
  margin-top: 30px;
}

.user-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(184, 184, 184, 0.5);
  margin-bottom: 30px;
  padding-top: 16px;
}

.user-card-image {
  background-color: var(--grey);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-style: double;
  border-color: white;
}

.user-card-name {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.user-card-ligth-grey {
  color: var(--grey-card-brand);
}
.user-card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #f6f6f6;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}

.user-card-ellipsis {
  min-width: 32px;
  width: 32px;
  height: 32px;
  display: flex;
  background-color: var(--header-grey);
  border-radius: 30%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 32px;
}

.user-card-status {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 20px;
  border: 5px solid #80e616;
  border-radius: 50%;
  margin-left: 48px;
  background-color: #FFFFFF;
}

.status-dot{
  height: 16px;
  width: 16px;
  border-radius: 50%;
}