@import '../../utilities/colors.css';

.footer {

  background-color: var(--whitesmoke);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #C8CCD5;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  bottom: 0;
  height: 80px;
}
