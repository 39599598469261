@import '../../../utilities/colors.css';

.modal-select-label {
  margin-top: 2px;
  margin-bottom: 3px;
}
.modal-select-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 14rem;
}

.modal-select {
  height: 38px;
  width: 100%;
  max-width: 15rem;
  border-radius: 9px !important;
  margin-top: 6px;
  
}

.MuiOutlinedInput-notchedOutline{
  border: 1px solid #ced4da !important;
}

.MuiSelect-iconOutlined{
  color: var(--blue-primary) !important;
}